import { SQLiteConnection, CapacitorSQLite } from '@capacitor-community/sqlite';
import { Capacitor } from '@capacitor/core';
import { getRestaurantCall, getTablesCall } from 'api/calendar';
import { RoomOrTable, Table } from 'types/calendar';
import moment from 'moment';
import { getReservationsCall } from 'api/calendar';
import { getFloorPlanCall } from 'api/floorPlan';
import { allData } from './databaseDefaultValues';
import { dbFullImport } from './databseFullImport';
import { getNotesRangeCall } from 'api/notes';
import { SpecialOffer } from 'types/reservation';

const platform = Capacitor.getPlatform();
export const dbName = 'db-full';

const mSQLite = new SQLiteConnection(CapacitorSQLite);
let database: any;

const getWeek = () => {
  const today = new Date();
  return {
    forward: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)).format(
      'YYYY-MM-DD',
    ),
    back: moment(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)).format(
      'YYYY-MM-DD',
    ),
  };
};

const loadJSON = async () => {
  const { back, forward } = getWeek();
  const response = await getReservationsCall(back, forward, null);
  const notesData = await getNotesRangeCall(back, forward);
  const roomsAndTables = await getTablesCall();
  const floorPlan = await getFloorPlanCall();
  const { restaurant } = await getRestaurantCall();
  const roomsOrTables: RoomOrTable[] = [];
  await database.delete();

  roomsAndTables.forEach((room: any) => {
    roomsOrTables.push({
      id: `${room.nid}`,
      roomId: room.nid,
      title: room.title,
      root: true,
      collapsed: room.collapsed,
    });
    room.tables.forEach((table: Table) => {
      roomsOrTables.push({
        id: `${table.nid}`,
        tableId: table.nid,
        title: table.title,
        root: false,
        parent: room.nid,
        covers_min: table.covers_min,
        covers_max: table.covers_max,
      });
    });
  });

  const allTables = roomsOrTables.filter((roomOrTable) => roomOrTable.tableId !== undefined);

  const allRooms = roomsOrTables.filter((roomOrTable) => roomOrTable.roomId !== undefined);

  const responseData = response?.data;
  const reservationValues: any = [];
  const tableValues: any = [];
  const clientValues: any = [];
  const currencyValues: any = [];
  const specialOfferValues: any = [];
  const commentValues: any = [];
  const floorPlanValues: any = [[restaurant.nid]];
  const floorPlanRoomsValues: any = [];
  const floorPlanTablesValues: any = [];
  const floorPlanWallsValues: any = [];
  const floorPlanTextsValues: any = [];
  const tablesValues: any = [];
  const roomsValues: any = [];
  const roomsAndTablesValues: any = [];
  const notesValues: any = [];

  const restaurantValues: any = [
    [
      restaurant.nid,
      restaurant.address.country,
      restaurant.changed,
      restaurant.created,
      restaurant.language,
      restaurant.status.toString(),
      restaurant.title,
    ],
  ];

  const restaurantSettingsValues: any = [
    [
      restaurant.nid,
      restaurant.settings.default_language,
      restaurant.settings.timezone,
      restaurant.settings.date_format_key,
      restaurant.settings.first_day_of_week,
      JSON.stringify(restaurant.settings.open_hours),
      JSON.stringify(restaurant.settings.exclusive_days),
      restaurant.settings.increment,
      restaurant.settings.list_show_cancelled,
      restaurant.settings.list_show_walk_ins,
      restaurant.settings.show_big_spender_tag,
    ],
  ];

  for (const room of floorPlan.rooms) {
    const roomRow = [
      room.changed,
      room.collapsed.toString(),
      room.created,
      room.language,
      room.nid,
      room.status.toString(),
      room.title,
    ];

    const tableRows: any = [];
    for (const table of room.tables) {
      const tableRow = [
        table.covers_min,
        table.covers_max,
        table.position.entity_id,
        table.position.bundle,
        table.position.restaurant_id,
        table.position.x,
        table.position.y,
        table.position.angle,
        table.position.width,
        table.position.height,
        table.position.shape,
        table.nid,
        table.title,
        table.language,
        table.status,
        table.created,
        table.changed,
        room.nid,
      ];
      tableRows.push(tableRow);
    }

    floorPlanRoomsValues.push(roomRow);
    floorPlanTablesValues.push(tableRows);
  }

  //TODO remove / change wall.position
  for (const wall of floorPlan.walls) {
    const wallRow = [
      wall.id,
      wall.type,
      wall.restaurant_id,
      wall.label,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    floorPlanWallsValues.push(wallRow);
  }

  const flatFloorPlanTablesValues = floorPlanTablesValues.flat();

  //TODO remove / change text.position
  for (const text of floorPlan.texts) {
    const textRow = [
      text.id,
      text.type,
      text.restaurant_id,
      text.label,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    floorPlanTextsValues.push(textRow);
  }

  for (const element of responseData) {
    const { reservation, clientData, clientAverageFeedback, reservationAverageFeedback } = element;
    const { rateAmbience, rateCleanliness, rateFood, rateService, rateComment, nid, title } =
      reservationAverageFeedback;
    const rTables = reservation?.tables.map((item: Table) => item.nid);
    const rSpecialOffers = reservation.specialOffers.map((item: SpecialOffer) => item.nid);
    const currency =
      reservation.payments.payments[0] && reservation.payments.payments[0].currencyCode;
    const totalAmount =
      reservation.payments.payments[0] && reservation.payments.payments[0].totalAmount;

    const dinningRooms = [
      //@ts-ignore
      ...new Set(
        reservation.tables.map((table: Table) => {
          return allTables.find((tab) => +tab.id === table.nid)?.parent;
        }),
      ),
    ];
    const ratings =
      clientAverageFeedback.ratings &&
      clientAverageFeedback.ratings[Object.keys(clientAverageFeedback.ratings)[0]];

    const reservationRow = [
      reservation.nid,
      reservation.title,
      reservation.changed,
      reservation.reservationStatus,
      reservation.timeInterval[0].startTime,
      reservation.timeInterval[0].endTime,
      reservation.covers,
      reservation.origin,
      reservation.payments.payments.toString(),
      reservation.payments.currencies.toString(),
      reservation.tags.toString(),
      dinningRooms.toString(),
      clientData.arrivedCount,
      clientData.cancelledCount,
      clientData.id,
      clientData.lastVisit,
      clientData.noShowCount,
      ratings?.average,
      ratings?.entityId,
      ratings?.type,
      rateAmbience,
      rateCleanliness,
      rateFood,
      rateService,
      rateComment,
      nid,
      title,
      clientAverageFeedback.feedbackCount,
    ];

    const tableRow = [reservation.nid, rTables.toString()];

    const clientRow = [
      reservation.nid,
      reservation.client[0].big_spender,
      reservation.client[0].changed,
      reservation.client[0].clientDetails,
      reservation.client[0].company,
      reservation.client[0].created,
      reservation.client[0].email,
      reservation.client[0].name,
      reservation.client[0].nid,
      reservation.client[0].phone,
      reservation.client[0].subscribed,
      reservation.client[0].vip,
    ];

    const currencyRow = [reservation.nid, currency, totalAmount];

    const specialOfferRow = [reservation.nid, rSpecialOffers.toString()];

    const commentsRows: any = [];
    for (const reply of reservation.replies) {
      const comment: any = [];
      comment.push(reservation.nid);
      comment.push(reply.changed);
      comment.push(reply.commentBody);
      comment.push(reply.id);
      comment.push(reply.uid);
      commentsRows.push(comment);
    }

    reservationValues.push(reservationRow);
    tableValues.push(tableRow);
    clientValues.push(clientRow);
    currencyValues.push(currencyRow);
    specialOfferValues.push(specialOfferRow);
    commentValues.push(commentsRows);
  }

  const flatComments = commentValues.flat();

  for (const table of allTables) {
    const tableRow: any = [
      table.id,
      table.covers_max,
      table.covers_min,
      table.parent,
      table.root,
      table.tableId,
      table.title,
    ];
    tablesValues.push(tableRow);
  }

  for (const room of allRooms) {
    const roomRow: any = [room.id, room.collapsed, room.roomId, room.root, room.title];
    roomsValues.push(roomRow);
  }

  for (const roomAndTable of roomsAndTables) {
    const roomAndTableRow = [
      roomAndTable.changed,
      roomAndTable.collapsed,
      roomAndTable.created,
      roomAndTable.language,
      roomAndTable.nid,
      roomAndTable.status,
      JSON.stringify(roomAndTable.tables),
      roomAndTable.title,
    ];
    roomsAndTablesValues.push(roomAndTableRow);
  }

  for (const note of notesData) {
    const noteRow = [
      note.color,
      JSON.stringify(note.days),
      JSON.stringify(note.days_excluded),
      note.end_date,
      note.id,
      note.name,
      note.restaurant_id,
      note.start_date,
      note.text,
      note.user_id,
    ];
    notesValues.push(noteRow);
  }

  dbFullImport.tables[0].values = reservationValues;
  dbFullImport.tables[1].values = tableValues;
  dbFullImport.tables[2].values = clientValues;
  dbFullImport.tables[3].values = currencyValues;
  dbFullImport.tables[4].values = specialOfferValues;
  dbFullImport.tables[5].values = flatComments;
  dbFullImport.tables[6].values = floorPlanValues;
  dbFullImport.tables[7].values = floorPlanRoomsValues;
  dbFullImport.tables[8].values = flatFloorPlanTablesValues;
  dbFullImport.tables[9].values = floorPlanWallsValues;
  dbFullImport.tables[10].values = floorPlanTextsValues;
  dbFullImport.tables[11].values = restaurantValues;
  dbFullImport.tables[12].values = restaurantSettingsValues;
  dbFullImport.tables[13].values = tablesValues;
  dbFullImport.tables[14].values = roomsValues;
  dbFullImport.tables[15].values = roomsAndTablesValues;
  dbFullImport.tables[16].values = notesValues;

  if (platform === 'web') {
    await mSQLite.saveToStore(dbName);
  }

  return await mSQLite.importFromJson(JSON.stringify(dbFullImport));
};

export const initdb = async (_isOffline?: boolean | null) => {
  try {
    if (platform === 'web') {
      const jeepEl = document.createElement('jeep-sqlite');
      const isJeepExist = document.getElementsByTagName('jeep-sqlite')?.length > 0;
      if (!isJeepExist) {
        document.body.appendChild(jeepEl);
      }
      await customElements.whenDefined('jeep-sqlite');
      await mSQLite.initWebStore();
    }
    const ret = await mSQLite.checkConnectionsConsistency();
    const isConn = (await mSQLite.isConnection(dbName, false)).result;

    if (ret.result && isConn) {
      database = await mSQLite.retrieveConnection(dbName, false);
    } else {
      database = await mSQLite.createConnection(dbName, false, 'no-encryption', 1, false);
    }

    if (!_isOffline) {
      await loadJSON();
    }

    return database;
  } catch (e) {
    return null;
  }
};

export const queryAllData = async () => {
  const ret = await mSQLite.checkConnectionsConsistency();
  const isConn = (await mSQLite.isConnection(dbName, false)).result;
  await database.open();

  if (ret.result && isConn) {
    await database
      .query('SELECT * from RESERVATIONS;')
      .then((i: any) => (allData['allReservations'] = i));
    await database.query('SELECT * from TABLES;').then((i: any) => (allData['allTables'] = i));
    await database.query('SELECT * from CLIENTS;').then((i: any) => (allData['allClients'] = i));
    await database
      .query('SELECT * from CURRENCIES;')
      .then((i: any) => (allData['allCurrencies'] = i));
    await database
      .query('SELECT * from SPECIAL_OFFERS;')
      .then((i: any) => (allData['allSpecialOffers'] = i));
    await database.query('SELECT * from COMMENTS;').then((i: any) => (allData['allComments'] = i));
    await database
      .query('SELECT * from FLOOR_PLAN;')
      .then((i: any) => (allData['allFloorPlan'] = i));
    await database
      .query('SELECT * from FLOOR_PLAN_ROOMS;')
      .then((i: any) => (allData['allFloorPlanRooms'] = i));
    await database
      .query('SELECT * from FLOOR_PLAN_TABLES;')
      .then((i: any) => (allData['allFloorPlanTables'] = i));
    await database
      .query('SELECT * from FLOOR_PLAN_WALLS;')
      .then((i: any) => (allData['allFloorPlanWalls'] = i));
    await database
      .query('SELECT * from FLOOR_PLAN_TEXTS;')
      .then((i: any) => (allData['allFloorPlanTexts'] = i));
    await database.query('SELECT * from RESTAURANT;').then((i: any) => (allData['restaurant'] = i));
    await database
      .query('SELECT * from RESTAURANT_SETTINGS;')
      .then((i: any) => (allData['restaurantSettings'] = i));
    await database
      .query('SELECT * from FULL_TABLES;')
      .then((i: any) => (allData['fullTables'] = i));
    await database.query('SELECT * from ROOMS;').then((i: any) => (allData['rooms'] = i));
    await database
      .query('SELECT * from ROOMS_AND_TABLES;')
      .then((i: any) => (allData['roomsAndTables'] = i));
    await database.query('SELECT * from NOTES;').then((i: any) => (allData['notes'] = i));
    return allData;
  }
};

export const queryRestaurant = async () => {
  const ret = await mSQLite.checkConnectionsConsistency();
  const isConn = (await mSQLite.isConnection(dbName, false)).result;
  await database.open();
  const allRestaurantData: any = {};
  if (ret.result && isConn) {
    await database
      .query('SELECT * from RESTAURANT;')
      .then((i: any) => (allRestaurantData['restaurant'] = i));
    await database
      .query('SELECT * from RESTAURANT_SETTINGS;')
      .then((i: any) => (allRestaurantData['restaurantSettings'] = i));
    return allRestaurantData;
  }
};

export const queryRoomsAndTables = async () => {
  const ret = await mSQLite.checkConnectionsConsistency();
  const isConn = (await mSQLite.isConnection(dbName, false)).result;
  await database.open();
  const roomsAndTablesData: any = {};
  if (ret.result && isConn) {
    await database
      .query('SELECT * from ROOMS_AND_TABLES;')
      .then((i: any) => (roomsAndTablesData['roomsAndTables'] = i));
    return roomsAndTablesData;
  }
};

export const queryNotes = async () => {
  const ret = await mSQLite.checkConnectionsConsistency();
  const isConn = (await mSQLite.isConnection(dbName, false)).result;
  await database.open();
  const notes: any = {};
  if (ret.result && isConn) {
    await database.query('SELECT * from NOTES;').then((i: any) => (notes['notes'] = i));
    return notes;
  }
};

export const deleteDatabase = async (): Promise<void> => {
  try {
    const ret: any = await database.isExists();
    if (ret.result) {
      await database.delete();
      return Promise.resolve();
    } else {
      return Promise.resolve();
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
